import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LuArrowLeft } from 'react-icons/lu';
import { UserInterface } from '../../app-schemas/profile.schema';
import profile_api from '../../api-services/profile/profile.services';
import { SITELINK } from '../../app-routes/Links';
import GlobalLayout from '../../layouts/GlobalLayout';
import LazyLoad from 'react-lazyload';
import { getFirstLetters, getUser } from '../../app-utils';
import {
  Loader,
  MessageImage,
  TelegramImage,
  WeChatImage,
  WhatsappImage,
} from '../../assets/custom-icons';

// main-component-ui
const ViewProfile = () => {
  document.body.classList.remove('overflow-hidden');

  const { userID } = useParams();

  // gloabl state localstorage
  const user: UserInterface = getUser();
  const [me, setMe] = useState<any>({});
  const [pageLoading, setPageLoading] = useState<boolean>(false);

  // use side effects
  useEffect(() => {
    myProfile(userID ?? '');
  }, [userID]);

  const myProfile = async (userID: string) => {
    setPageLoading(true);
    const my_profile: any = await profile_api.ProfileById(userID);
    setMe(my_profile.data.data);
    setPageLoading(false);
  };

  const chatOnWhatsapp = (number: number | null) => {
    const url = `https://wa.me/${number}`;
    window.open(url, '_blank'); // Open in a new tab
  };
  const handleTelegramClick = (number: number | null) => {
    const url = `tg://openchat?phone=${number}`;
    window.open(url, '_blank'); // Open in a new tab
  };

  return (
    <GlobalLayout title="Catapot | My Profile">
      <section className="">
        {/* top lead area and post btn */}
        <div className="fixed top-0 w-full z-50 shadow-lg">
          <div className="flex gap-4 relative px-2 py-3 bg-custom-600 text-white">
            <Link to={SITELINK.HOME} className="font-semibold text-sm flex">
              <LuArrowLeft className="text-2xl mt-[2px]" />
            </Link>

            <p className="text-lg font-medium">Profile</p>
          </div>
        </div>

        {/* content profile-info area */}
        {pageLoading ? (
          <div className="h-[70vh] flex justify-center items-center">
            <img src={Loader} alt="pre-loader" className="w-[20px] h-[20px]" />
          </div>
        ) : (
          <div className="mt-[48px]">
            <div className="bg-[#fff] flex gap-3 items-start justify-start py-3 px-3 pb-3 border-b">
              {/* profile image side */}
              <div className="overflow-hidden flex justify-center">
                <div className="relative">
                  {me?.profile_picture_url ? (
                    <LazyLoad height={300} offset={1000} once>
                      <img
                        alt="me"
                        src={me?.profile_picture_url}
                        className="border-[1px] object-cover rounded-full w-[60px] h-[60px] bg-gray-200 shadow-sm overflow-hidden"
                      />
                    </LazyLoad>
                  ) : (
                    <span className="block w-[60px] h-[60px] bg-[#223f64] rounded-full border border-green-100">
                      <h3 className="text-white text-[3rem] uppercase font-[600] relative left-5">
                        {getFirstLetters(me?.first_name ?? 'C')}
                      </h3>
                    </span>
                  )}
                </div>
              </div>

              <div className="mt-2">
                <div className="flex justify-between">
                  <h3 className="font-semibold text-2xl mb-0">{me?.username ?? user?.username}</h3>

                  {user.username === me?.username && (
                    <div>
                      <Link
                        to={SITELINK.EDIT_PROFILE}
                        className="text-sm font-medium rounded border border-gray-300 px-3 py-1 w-full mt-3 bg-gray-100"
                      >
                        Edit
                      </Link>
                    </div>
                  )}
                </div>

                <p className="text-xs text-medium text-gray-500 hidden">
                  {me?.first_name} {me?.last_name}
                </p>
                <p className="text-xs text-medium text-gray-500">{me?.email}</p>
              </div>
            </div>

            {/* complete form area */}
            <div className="w-full bg-white p-3 grid-cols-2 gap-3 mt-1">
              <label className="text-xs font-medium">Business Name</label>
              <p className="text-xs">{me?.business_name ?? 'No Business name added'}</p>
            </div>

            {/* complete form area */}
            <div className="w-full bg-white p-3 grid-cols-2 gap-3 mt-1">
              <label className="text-xs font-medium">Full Name</label>
              <p className="text-xs">
                {me?.first_name} {me?.last_name}
              </p>
            </div>

            {/* complete form area */}
            <div className="w-full bg-white p-3 grid-cols-2 gap-3 mt-1">
              <label className="text-xs font-medium">Bio</label>
              <p className="text-xs">{me?.bio ?? 'Hello i am available'}</p>
            </div>

            {/* complete form area */}
            <div className="w-full bg-white p-3 grid-cols-2 gap-3 mt-1">
              <label className="text-xs font-medium">City</label>
              <p className="text-xs">{me?.residency_city}</p>
            </div>

            {/* complete form area */}

            {user.username !== me?.username && (
              <div className="w-full bg-white p-3 grid-cols-2 gap-3 mt-1">
                <label className="text-xs font-medium">Start a conversation</label>

                <div className="flex lg:justify-between gap-4 lg:gap-16 items-center">
                  <img
                    src={WhatsappImage}
                    alt=""
                    className="cursor-pointer"
                    onClick={() => chatOnWhatsapp(me?.whatsapp_number)}
                  />

                  <img
                    src={TelegramImage}
                    alt=""
                    className="cursor-pointer"
                    onClick={() => handleTelegramClick(me?.phone_number)}
                  />

                  <img src={WeChatImage} alt="" />

                  <Link
                    to={SITELINK.CHATIN}
                    onClick={() => {
                      localStorage.setItem(
                        'lastChattedUserInfo',
                        JSON.stringify({
                          id: me?.user_id,
                          name: me?.username,
                          image: me?.profile_picture,
                        }),
                      );
                    }}
                  >
                    <img src={MessageImage} alt="" />
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </GlobalLayout>
  );
};

export default ViewProfile;
