import { makeRequestWithToken } from '../services';

class Profile {
  async Profile(): Promise<any> {
    try {
      return await makeRequestWithToken.get(`profile/fetch`); // profile
    } catch (error) {
      return error;
    }
  }

  async ProfileById(userID:string): Promise<any> {
    try {
      return await makeRequestWithToken.get(`profile/fetch/${userID}`); // profile
    } catch (error) {
      return error;
    }
  }

  async UpdateProfile(data: any): Promise<any> {
    try {
      const formdata = new FormData();
      if (!!data.profile_picture) formdata.append('profile_picture', data.profile_picture);
      if (!!data.username) formdata.append('username', data.username);
      if (!!data.first_name) formdata.append('first_name', data.first_name);
      if (!!data.last_name) formdata.append('last_name', data.last_name);
      if (!!data.nationality) formdata.append('nationality_country_id', data.nationality);
      if (!!data.latitude) formdata.append('latitude', data.latitude);
      if (!!data.longitude) formdata.append('longitude', data.longitude);
      if (!!data.marital_status) formdata.append('marital_status', data.marital_status);
      if (!!data.whatsapp_number) formdata.append('whatsapp_number', data.whatsapp_number);
      if (!!data.gender) formdata.append('gender', data.gender);
      if (!!data.bio) formdata.append('bio', data.bio);
      if (!!data.email) formdata.append('email', data.email);
      if (!!data.profession) formdata.append('profession', data.profession);
      if (!!data.hobby) formdata.append('hobby', data.hobby);
      if (!!data.religion) formdata.append('religion', data.religion);

      return await makeRequestWithToken.post('profile/update', formdata);
    } catch (error) {
      return error;
    }
  }

  async UpdateLocation(data: any): Promise<any> {
    try {
      const formdata = new FormData();
      if (!!data.latitude) formdata.append('latitude', data.latitude);
      if (!!data.longitude) formdata.append('longitude', data.longitude);

      return await makeRequestWithToken.post('profile/geolocateCommunities', formdata);
    } catch (error) {
      return error;
    }
  }

  async GeoLocateUser(data: any): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append('lat', data.lon);
      formdata.append('lon', data.lon);

      return await makeRequestWithToken.post('profile/geolocate', formdata); // list of countries
    } catch (error) {
      return error; // server error
    }
  }
}

const profile_api = new Profile();
export default profile_api;
