import { FormEvent, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ButtonComponent from '../../components/ButtonComponent';
import InputField from '../../components/InputField';
import TextArea from '../../components/TextArea';
import useFormInput from '../../custom-hooks/useFormInput';
import {
  LuArrowLeft,
  LuGlobe,
  LuImage,
  LuUser2,
  LuUsers,
  LuMail,
  LuChurch,
  LuUserCog2,
  LuBookOpen,
  LuCamera,
} from 'react-icons/lu';
import { z } from 'zod';
import SelectDropdown from '../../components/SelectDropdown';
import {
  UserOnboardingType,
  AccountSetupSchema,
  AccountSetupType,
  UserInterface,
} from '../../app-schemas/profile.schema';
import content_mgt from '../../api-services/contents/contents_data.services';
import profile_api from '../../api-services/profile/profile.services';
import { RES_MSSG } from '../../api-services/ConstMssg';
import { SITELINK } from '../../app-routes/Links';
import GlobalLayout from '../../layouts/GlobalLayout';
import LazyLoad from 'react-lazyload';
import { getFirstLetters, getUser, storeUser } from '../../app-utils';
import Modal from '../../components/modal';
import { Loader } from '../../assets/custom-icons';

// data type
type Location = {
  latitude: number;
  longitude: number;
};

// custom js fn to handle type Guard
function isLocation(value: any): value is Location {
  return value && typeof value.latitude === 'number' && typeof value.longitude === 'number';
}

// main-component-ui
const ProfileAccount = () => {
  document.body.classList.remove('overflow-hidden');
  // const navigate = useNavigate();

  // gloabl state localstorage
  const user: UserInterface = getUser();

  // local state here;
  const [me, setMe] = useState<Partial<UserInterface>>({});
  const [imageModal, setImageModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<any>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isProfilePix, setIsProfilePix] = useState(false);

  const AccountSetupDefault: AccountSetupType = {
    first_name: '',
    last_name: '',
    username: '',
    profession: '',
    hobby: '',
    email: '',
    nationality_country_id: -1,
    whatsapp_number: '',
    marital_status: '',
    religion: '',
    gender: '',
    bio: '',
  };
  const [profilePix, setProfilePix] = useState('');
  const [locationAccessMessage, setLocationAccess] = useState<string>('OPEN');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const maritalStatus = [
    { value: 'Single', label: 'Single' },
    { value: 'Married', label: 'Married' },
    { value: 'Divorced', label: 'Divorced' },
    { value: 'Separated', label: 'Separated' },
    { value: 'Single Parent', label: 'Single Parent' },
  ];

  const religion = [
    { value: 'Christianity', label: 'Christianity' },
    { value: 'Islam', label: 'Islam' },
    { value: 'Buddhism', label: 'Buddhism' },
    { value: 'Judaism', label: 'Judaism' },
    { value: 'Traditionalist', label: 'Traditionalist' },
    { value: 'Atheist', label: 'Atheist' },
  ];

  const createImage = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && typeof e.target.result === 'string') {
        const imgElement = document.createElement('img');
        imgElement.src = e.target?.result as string;
        imgElement.style.width = '52px'; // Adjust the size as needed
        imgElement.style.height = '52px'; // Adjust the size as needed
        imgElement.style.objectFit = 'cover';
        imgElement.style.borderRadius = '50%';
        imgElement.id = 'imgElement';

        const imageContainer = document.getElementById('imageContainer');
        if (imageContainer) {
          imageContainer.appendChild(imgElement);
        }
      }
    };

    reader.readAsDataURL(file);
  };

  const handleFileChange = (value: FileList | null) => {
    if (value !== null) {
      setFile(value[0]);
      createImage(value[0]);
      setIsProfilePix(true);

      let data: any = {
        profile_picture: value[0],
        longitude: me?.longitude,
        latitude: me?.latitude,
      };
      uploadProfileImage(data);
    }
  };

  const removeImage = () => {
    const imgElement = document.getElementById('imgElement');
    if (imgElement) {
      imgElement.remove();
      setFile(null);
      setIsProfilePix(false);
    }
  };

  // use side effects
  useEffect(() => {
    myProfile();
  }, []);

  const myProfile = async () => {
    setPageLoading(true);
    const my_profile: any = await profile_api.Profile();
    setMe(my_profile?.data?.data);
    setPageLoading(false);
  };

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line
  }, []);

  // custom hook to get && validate users input
  const [formData, handleChange, errors, setValidationErrors, handleCustomChange] = useFormInput(
    AccountSetupDefault,
    AccountSetupSchema,
  );

  // fn to get countries
  // store res in react state
  const getCountries = async () => {
    const res = await content_mgt.Countries();
    const options = res.data?.data?.map((i: any) => ({ value: i.country_id, label: i.country_name }));

    setCountries(options);
  };

  // code to get user current location
  // to be modified to custom hooks if needed in multiple places
  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      setLocationAccess('OPEN');

      if ('permissions' in navigator) {
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
          if (permissionStatus.state === 'denied') {
            setErrorMsg(
              'Location access is blocked. To use this feature, please enable location services in your browser settings.',
            );
            setLocationAccess('DENIED');
          }
        });
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords);
          },
          (error) => {
            setErrorMsg(`Error getting location`);
            reject(null);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          },
        );
      } else {
        const error = 'Geolocation is not supported by this device.';
        setErrorMsg(error);
        reject(new Error(error));
      }
    });
  };

  const getUserProfile = async () => {
    setLoading(true);
    try {
      const res = await profile_api.Profile();

      if (res?.data?.message === RES_MSSG.SUCCESS) {
        Object.assign(AccountSetupDefault, res.data.data);
        setProfilePix(res.data.data.profile_picture_url);
        if (res.data.data.whatsapp_number === null) {
          AccountSetupDefault.whatsapp_number = '';
        }
      } else {
        setErrorMsg(res?.response?.data?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setLoading(false);
    }
  };

  // fn to handleOnboarding
  const handleOnboarding = async (e: FormEvent) => {
    e.preventDefault();
    const { success, data, error } = AccountSetupSchema.safeParse(formData);

    console.log(data, success);

    if (formData) {
      const position = await getUserLocation();
      if (isLocation(position)) {
        const coordinates: Location = position;

        let data = {
          username: formData.username,
          first_name: formData.first_name,
          last_name: formData.last_name,
          nationality: formData.nationality_country_id,
          longitude: coordinates.longitude,
          latitude: coordinates.latitude,
          marital_status: formData.marital_status,
          whatsapp_number: formData.whatsapp_number,
          gender: formData.gender,
          bio: formData.bio,
          email: formData.email,
          profession: formData.profession,
          hobby: formData.hobby,
          religion: formData.religion,
          profile_picture: file,
        };

        OnbaordUserWithLocation(data);
      } else {
        setErrorMsg('Location is required to proceed.');
      }
    } else {
      if(error)
      handleValidationErrors(error);
    }
  };

  // main method to handle err && res
  const OnbaordUserWithLocation = async (data: UserOnboardingType) => {
    setLoading(true);
    console.log(data);

    try {
      const res = await profile_api.UpdateProfile(data);

      if (res?.data?.message === RES_MSSG.PROFILE_UPDATED) {
        const res: any = await myProfile();
        let me_local = getUser();
        me_local.profile_picture = res?.data?.data?.profile_picture_url;
        storeUser(me_local);

        // navigate(SITELINK.HOME);
      } else {
        setErrorMsg(res?.response?.data?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setLoading(false);
    }
  };

  // main method to handle err && res
  const uploadProfileImage = async (data: any) => {
    setPageLoading(true);

    try {
      const res = await profile_api.UpdateProfile(data);

      if (res?.data?.message === RES_MSSG.PROFILE_UPDATED) {
        const res: any = await myProfile();
        let me_local = getUser();
        me_local.profile_picture = res?.data?.data?.profile_picture_url;
        storeUser(me_local);
      } else {
        setErrorMsg(res?.response?.data?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setPageLoading(false);
    }
  };

  const handleValidationErrors = (error: z.ZodError<AccountSetupType>) => {
    const newErrors: Partial<Record<keyof AccountSetupType, string>> = {};
    error.errors.forEach((err) => {
      newErrors[err.path[0] as keyof AccountSetupType] = err.message;
    });
    setValidationErrors(newErrors);
  };

  return (
    <GlobalLayout title="Catapot | My Profile">
      <section className="">
        {/* top lead area and post btn */}
        <div className="fixed top-0 w-full z-50 shadow-lg">
          <div className="flex gap-4 relative px-2 py-3 bg-custom-600 text-white">
            <Link to={SITELINK.HOME} className="font-semibold text-sm flex">
              <LuArrowLeft className="text-2xl mt-[2px]" />
            </Link>

            <p className="text-lg font-medium">My Profile</p>
          </div>
        </div>

        {/* content profile-info area */}
        {pageLoading ? (
          <div className="h-[70vh] flex justify-center items-center">
            <img src={Loader} alt="pre-loader" className="w-[20px] h-[20px]" />
          </div>
        ) : (
          <div className="mt-[48px]">
            <div className="bg-[#fff] flex gap-5 items-start justify-start py-3 px-3 pb-3 border-b">
              {/* profile image side */}
              <div className="overflow-hidden flex justify-center">
                <div className="relative">
                  {me?.profile_picture_url ? (
                    <LazyLoad height={300} offset={1000} once>
                      <img
                        alt="me"
                        src={me?.profile_picture_url}
                        className="border-[1px] object-cover rounded-full w-[80px] h-[80px] bg-gray-200 shadow-sm overflow-hidden"
                      />
                    </LazyLoad>
                  ) : (
                    <span className="block w-[80px] h-[80px] bg-[#223f64] rounded-full border border-green-100">
                      <h3 className="text-white text-[3rem] uppercase font-[600] relative left-5">
                        {getFirstLetters(me?.first_name ?? 'C')}
                      </h3>
                    </span>
                  )}

                  {/* trigers image modal */}
                  <button className="text-gray-500 text-lg bg-gray-50 border shadow-sm absolute bottom-1 right-[0px] p-1.5 rounded-full">
                    <label htmlFor="dropzone-file" className="text-[13px] cursor-pointer">
                      <LuCamera />
                      <input
                        id="dropzone-file"
                        ref={fileInputRef}
                        type="file"
                        accept=".png, .jpeg, .jpg"
                        onChange={(e) => handleFileChange(e.currentTarget.files)}
                        className="hidden"
                      />
                    </label>
                  </button>
                </div>
              </div>

              <div className="mt-2">
                <h3 className="font-semibold text-2xl mb-0">{me?.username ?? user?.username}</h3>
                <p className="text-xs text-medium text-gray-500">
                  {me?.first_name} {me?.last_name}
                </p>
                <p className="text-xs text-medium text-gray-500">{me?.email}</p>

                <div>
                  <button className="hidden text-sm font-medium rounded-[50px] border border-gray-300 px-3 py-1 w-full mt-3 bg-gray-100">
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>

            <div className="text-red-600 text-[10px] col-span-full text-center hidden">
              {errorMsg}
            </div>

            {/* complete form area */}
            <form onSubmit={handleOnboarding} className="grid bg-white mb-12 p-3 grid-cols-2 gap-3">
              <InputField
                className="col-span-full text-sm rounded-lg"
                type="text"
                id="first_name"
                placeholder="First Name"
                label="First Name"
                value={formData.first_name}
                onChange={handleChange}
                required
                icon={LuUser2}
                error={errors?.first_name}
              />

              <InputField
                className="col-span-full text-sm rounded-lg"
                type="text"
                id="last_name"
                placeholder="Last Name"
                label="Last Name"
                value={formData.last_name}
                onChange={handleChange}
                required
                icon={LuUser2}
                error={errors?.last_name}
              />

              <InputField
                className="col-span-full text-sm rounded-lg hidden"
                type="text"
                id="username"
                placeholder="Nickname"
                label="Nickname"
                value={formData.username}
                onChange={handleChange}
                required
                icon={LuUser2}
                error={errors?.username}
              />

              <InputField
                className="col-span-full text-sm rounded-lg hidden"
                type="email"
                id="email"
                placeholder="Email"
                label="Email"
                value={formData.email}
                onChange={handleChange}
                required
                icon={LuMail}
                error={errors?.email}
                disabled
              />

              <InputField
                className="col-span-full text-sm rounded-lg hidden"
                type="profession"
                id="profession"
                placeholder="Profession"
                label="Profession"
                value={formData.profession}
                onChange={handleChange}
                required
                icon={LuUserCog2}
                error={errors?.profession}
              />

              <InputField
                className="col-span-full text-sm rounded-lg hidden"
                type="hobby"
                id="hobby"
                placeholder="Hobby"
                label="Hobby"
                value={formData.hobby}
                onChange={handleChange}
                required
                icon={LuUserCog2}
                error={errors?.hobby}
              />

              <InputField
                className="col-span-full text-sm rounded-lg hidden"
                type="tel"
                id="whatsapp_number"
                placeholder="Whatsapp Number"
                label="Whatsapp Number"
                value={formData.whatsapp_number}
                onChange={handleChange}
                required
                icon={LuUser2}
                error={errors?.whatsapp_number}
              />

              <div className="col-span-full relative">
                <label className="flex text-xs text-gray-700 font-[600]">Gender</label>
                <div className="col-span-full relative">
                  <span className="absolute p-[5px] rounded-[50px] top-[8px] left-[7px] text-xl text-[#98A1B0]">
                    <LuUser2 />
                  </span>
                  <select
                    className="col-span-full text-sm rounded-lg focus:outline-none focus:shadow-outline border border-input border-gray-400 rounded-[3px] pl-10 px-4 py-3 w-full text-md text-gray-700 focus:shadow-lg focus:border-[#58595c]"
                    value={formData.gender}
                    onChange={(e) => handleCustomChange('gender', e.target.value)}
                  >
                    <option value={''} disabled>
                      Select Gender
                    </option>
                    <option value={'Male'}>Male</option>
                    <option value={'Female'}>Female</option>
                    <option value={'Others'}>Rather not specify</option>
                  </select>
                </div>
              </div>

              <div className="col-span-full relative">
                <label className="flex text-xs text-gray-700 font-[600]">Nationality</label>
                <div className="col-span-full relative">
                  <span className="absolute p-[5px] rounded-[50px] top-[8px] left-[7px] text-xl text-[#98A1B0]">
                    <LuGlobe />
                  </span>
                  <select
                    className="col-span-full text-sm rounded-lg focus:outline-none focus:shadow-outline border border-input border-gray-400 rounded-[3px] pl-10 px-4 py-3 w-full text-md text-gray-700 focus:shadow-lg focus:border-[#58595c]"
                    value={formData.nationality_country_id}
                    onChange={(e) => handleCustomChange('nationality_country_id', e.target.value)}
                  >
                    <option value={''} disabled>
                      Select your Nationality
                    </option>
                    {countries?.map((data: any) => {
                      return <option key={data.value} value={data.value}>{data.label}</option>;
                    })}
                  </select>
                </div>
              </div>

              <SelectDropdown
                className="col-span-full text-sm rounded-lg hidden"
                id="marital_status"
                placeholder="Select your Marital Status"
                label="Marital Status"
                value={formData.marital_status}
                onChange={(e) => handleCustomChange('marital_status', e)}
                options={maritalStatus}
                icon={LuUsers}
                required
                error={errors?.marital_status}
              />

              <SelectDropdown
                className="col-span-full text-sm rounded-lg hidden"
                id="religion"
                placeholder="Select your Religion"
                label="Religion"
                value={formData.religion}
                onChange={(e) => handleCustomChange('religion', e)}
                options={religion}
                icon={LuChurch}
                required
                error={errors?.religion}
              />

              <TextArea
                className="col-span-full text-sm rounded-lg"
                id="bio"
                placeholder="Bio"
                label="About"
                icon={LuBookOpen}
                value={formData.bio}
                onChange={handleChange}
                required
                error={errors?.bio}
              />

              {/* <TextArea
                className="col-span-full text-sm rounded-lg"
                id="bio"
                placeholder="Bio"
                label="About"
                icon={LuBookOpen}
                value={formData.bio}
                onChange={handleChange}
                required
                error={errors?.bio}
              /> */}

              <div className="col-span-full">
                {locationAccessMessage === 'DENIED' && (
                  <div className="text-[12px] col-span-full flex bg-red-50 border rounded-md py-1 px-3">
                    <div>
                      <p>
                        Location access is blocked. To use this feature, please enable location
                        services in your browser settings.
                      </p>
                      <ol className="ol">
                        <li>1. Open Chrome settings.</li>
                        <li>2. Go to "Site settings".</li>
                        <li>3. Tap "Location".</li>
                        <li>4. Find and select your website.</li>
                        <li>5. Allow location access.</li>
                      </ol>
                      <button className="bg-gray-500 text-green-100 py-1 px-4 mt-3 mb-1 border rounded shadow">
                        Retry
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/* action btn */}
              <ButtonComponent
                className="w-[200px] mt-2 text-sm  py-3 rounded-lg text-white bg-custom-600 mb-8"
                type="submit"
                disabled={loading}
                loading={loading}
              >
                Update
              </ButtonComponent>
            </form>
          </div>
        )}
      </section>

      <Modal
        isOpen={imageModal}
        onClose={() => {
          setImageModal(!imageModal);
        }}
      >
        <section className="p-3 w-[70vw]">
          <div className="flex justify-end">
            <button
              className="text-red-600 font-medium text-xs justify-end flex p-3"
              onClick={() => {
                setImageModal(!imageModal);
              }}
            >
              Close
            </button>
          </div>

          <p className="text-center text-semibold text-sm">Upload image</p>

          <div className="p-24 border border-dotted border-gray-400 rounded-lg">
            <div className="flex gap-2 items-center">
              {isProfilePix ? (
                <span
                  className="text-red-600 text-[13px] font-medium cursor-pointer"
                  onClick={removeImage}
                >
                  Remove Picture
                </span>
              ) : (
                <label
                  htmlFor="dropzone-file"
                  className="text-[13px] flex py-1 gap-1 cursor-pointer"
                >
                  <LuImage className="text-lg text-custom-600" />
                  <div id="imgElement"></div>

                  <span className="text-custom-600 font-medium">
                    {profilePix !== '' && !isProfilePix
                      ? 'Change Profile Picture'
                      : 'Add Profile Picture'}{' '}
                  </span>

                  <input
                    id="dropzone-file"
                    ref={fileInputRef}
                    type="file"
                    accept=".png, .jpeg, .jpg"
                    onChange={(e) => handleFileChange(e.currentTarget.files)}
                    className="hidden"
                  />
                </label>
              )}
            </div>
            {/* <PiImageDuotone className="text-3xl" /> */}
          </div>
        </section>
      </Modal>
    </GlobalLayout>
  );
};

export default ProfileAccount;
