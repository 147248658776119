// library/dependencies import;
import UserLogin from '../pages/auth/LoginUserII';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SITELINK } from './Links';

import RequestResetPassword from '../pages/auth/RequestResetPassword';
import VerifyEmail from '../pages/auth/VerifyEmail';
import ProfileSetup from '../pages/profile/ProfileSetup';
import Home from '../pages/home/Home';
import Messages from '../pages/messaging_old/Messages';
import Community from '../pages/community/Community';
import CommunityDetails from '../pages/community/CommunityDetails';
import Settings from '../pages/settings/Settings';
import CreateCommunity from '../pages/community/CreateCommunity';
import Composer from '../pages/home/Composer';
import CommunityComposer from '../pages/community/CommunityComposer';
import Search from '../pages/home/Search';
import Notifications from '../pages/home/Notifications';
import Subscription from '../pages/subscription/Subscription';
import SavedPost from '../pages/home/SavedPosts';
import LikedPosts from '../pages/home/LikedPosts';
import ViewPost from '../pages/home/ViewPost';
import MoreCommunity from '../pages/community/MoreCommunity';
import MySubscription from '../pages/subscription/MySubscription';
import GoogleCallback from '../pages/auth/GoogleCallback';
import Messaging from '../pages/messaging/messaging';
import ViewProfile from '../pages/profile/ViewProfile';
import ProfileAccount from '../pages/profile/ProfileAccount';
// import useAuth from './useAuth';
// import { getUser } from '../app-utils';
// import { ReactNode } from 'react';
// import Preloader from '../components/preloader/Preloader';

// Middleware to validate token
// function ProtectedRoute({ children }: { children: ReactNode }) {
//   const { isAuthenticated, loading } = useAuth();
//   const user = getUser();

//   // Show preloader while loading
//   if (loading) {
//     return (
//       <div>
//         <Preloader loading={loading} />
//       </div>
//     );
//   }

//   if (isAuthenticated) {
//     if (!user?.username || !user?.DOB || !user?.country_id) {
//       return <Navigate to={SITELINK.HOME} replace />;
//     }
//   } else {
//     localStorage.clear();
//   }

//   return isAuthenticated ? <>{children}</> : <Navigate to={SITELINK.LOGIN} replace />;
// }

// function ProtectedAuthRoute({ children }: { children: ReactNode }) {
//   const { isAuthenticated, loading } = useAuth();

//   // Show preloader while loading
//   if (loading) {
//     return (
//       <div>
//         <Preloader loading={loading} />
//       </div>
//     );
//   }

//   return isAuthenticated ? <>{children}</> : <Navigate to={SITELINK.LOGIN} replace />;
// }

// config routes
function APPRoutes() {
  return (
    <Router>
      <Routes>
        {/* authentication routes */}
        <Route path="/" element={<UserLogin />} /> {/* app website / landing */}
        <Route path={SITELINK.LOGIN} element={<UserLogin />} />
        <Route path={SITELINK.GOOGLE_LOGIN} element={<GoogleCallback />} />
        <Route path={SITELINK.REQUEST_RESET} element={<RequestResetPassword />} />
        <Route path={SITELINK.AUTH_2FA} element={<VerifyEmail />} />
        <Route path={SITELINK.PROFILE_SETUP} element={<ProfileSetup />} />
        {/* home page link and sub link */}
        <Route path={SITELINK.HOME} element={<Home />} />
        <Route path={`${SITELINK.VIEW_POSTS}/:id`} element={<ViewPost />} />
        <Route path={SITELINK.COMPOSER} element={<Composer />} />
        <Route path={`${SITELINK.COM_COMPOSER}/:id`} element={<CommunityComposer />} />
        <Route path={`${SITELINK.APP_SEARCH}`} element={<Search />} />
        <Route path={`${SITELINK.APP_SEARCH}/:search`} element={<Search />} />
        <Route path={`${SITELINK.NOTIFICATIONS}`} element={<Notifications />} />
        <Route path={`${SITELINK.BOOKMARKS}`} element={<SavedPost />} />
        <Route path={`${SITELINK.LIKED_POSTS}`} element={<LikedPosts />} />
        <Route path={SITELINK.COMMUNITIES} element={<Community />} />
        <Route path={SITELINK.MORE_COMMUNITIES} element={<MoreCommunity />} />
        <Route path={SITELINK.NEW_COMMUNITIES} element={<CreateCommunity />} />
        <Route path={SITELINK.MESSAGES} element={<Messages />} />
        <Route path={`${SITELINK.VIEW_MESSAGES}/:id`} element={<Messages />} />
        <Route path={SITELINK.PRIVACY_AND_SETTINGS} element={<Settings />} />
        <Route path={SITELINK.SIGNUP} element={<>sign-up</>} />
        <Route path={SITELINK.AUTH_2FA} element={<>auth-2fa</>} />
        <Route path={SITELINK.REQUEST_RESET} element={<>request-reset</>} />
        <Route
          path={`${SITELINK.RESET_PASSWORD}/:id1/id2`}
          element={<>reset-password/:id1/id2</>}
        />
        <Route path={SITELINK.SUBSCRIPTIONS} element={<Subscription />} />
        <Route path={SITELINK.MY_SUBSCRIPTION_HISTORY} element={<MySubscription />} />
        <Route path={SITELINK.CHECKOUT} element={<>subscription-checkout</>} />


        <Route path={SITELINK.MY_PROFILE} element={<ProfileAccount />} />
        <Route path={`${SITELINK.MY_PROFILE}/:userID`} element={<ViewProfile />} />


        <Route path={SITELINK.BADGE_VERIFICATION} element={<>badge-account-verification</>} />
        <Route
          path={`${SITELINK.COMMUNITIES_SEARCH}/:searchWith`}
          element={<>communities/search/:searchWith</>}
        />
        <Route path={`${SITELINK.COMMUNITY_ABOUT}/:id`} element={<>community/about/:id</>} />
        <Route path={`${SITELINK.COMMUNITY_ACTIVITIES}/:id`} element={<CommunityDetails />} />
        <Route path={`${SITELINK.COMMUNITY_MEMBERS}/:id`} element={<>community/members/:id</>} />
        <Route
          path={`${SITELINK.FIND_MESSAGES}/:searchWith`}
          element={<>message/search/:searchWith</>}
        />

        <Route path={`${SITELINK.APP_SEARCH}/:searchWith`} element={<>search/all/:searchWith</>} />
        <Route path={SITELINK.CHAT} element={<Messaging />} />
        <Route path={`${SITELINK.CHATIN}`} element={<Messaging />} />
      </Routes>
    </Router>
  );
}

export default APPRoutes;
